/* DataTableDemo.css */

.datatable-editing-demo .editable-cells-table td.p-cell-editing {
    padding-top: 0;
    padding-bottom: 0;
}


#editsolo  .botoncito:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover  {
    background: #A1A1AA !important;
    color: #fff !important;
    border-color: #212121 !important;
}