.fondoHero{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../../../assets/img/storefront-1-18.png);
    /*background-image: url(./assets/img/storefront-1-18.png);*/
}

.fondoCate1{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url(../../../assets/img/category-preview-1-9.png);
}

.fondoCate2{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url(../../../assets/img/category-preview-1-10.png);
}

.fondoCate3{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url(../../../assets/img/category-preview-1-11.png);
}



.swiper-wrapper.swiper-button-next{
  color: #454546;
}
.swiper-button-prev{
  color: #454546;
}
.swiper-pagination-bullet-active{
  background-color: #454546;
}

.stiloTrue{
  /*object-fit: fill !important;*/
  object-fit: contain !important;
  background-repeat:no-repeat !important;
  background-size: 100% 100% !important;
  
}


.h-33rem {
  height: 33rem !important;
}
.h-35rem {
  height: 35rem !important;
}
.h-37rem {
  height: 37rem !important;
}
.h-39rem {
  height: 39rem !important;
}
.h-40rem {
  height: 40rem !important;
}
.h-45rem {
  height: 45rem !important;
}

.clip{
  clip-path: inset(40px 40px 40px 40px) !important;
}


@media screen and (max-width: 576px) {
  .stiloTrue{
    
    object-fit: fill !important;
    
  }
  .hdef{
    height: 15rem !important;
  }
}

@media screen and (min-width: 576px) {
  .hdef{
    height: 30rem !important;
  }
}




