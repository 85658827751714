.rtf--mb__c{
    margin: 0;
    padding: 0;
}

/* CarouselDemo.css */

.carousel-demo .product-item .product-item-content {
    /*border: 1px solid var(--surface-d); */
    margin: .8rem;
    text-align: center;
    padding: 2rem 0;
  }
  
  .carousel-demo .product-item .product-image {
    width: 80%;
    /*box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  }
     
  
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: black !important;
    color: #312E81;
  }