
#edito .p-highlight {
    border-color: #18181B !important;
    background: #18181B !important;
}
#edito .p-checkbox-box-focused{
    border-color: #18181B !important;
    outline: none !important;
}
#edito .p-focus {
    box-shadow: none !important;
    border-color: #18181B !important;
} 

