.minAlto {
    min-height: 29rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: var(--surface-400) !important;
    cursor: pointer;

}
.galleria-demo .p-galleria-indicators  .p-highlight .indicator-text {
    color: var(--primary-color) !important;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--surface-900) !important;
    color: var(--primary-color) !important;
}

.p-galleria .p-galleria-item-nav {
    color: var(--surface-900) !important;
}

.p-accordion-header .p-highlight {
    border-color: #212121 !important;
}
.p-accordion-header .p-highlight{
    border-color: #212121 !important;
}

.p-accordion .p-component .p-accordion-tab .p-accordion-header:focus {
    border-color: #212121 !important;
}

    .box #imagenPrueba img{
        transition: all 900ms;
    }
    .box:hover #imagenPrueba img {
        transform: scale(1.03);
        /*cursor:none; */
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
    }

#tablecito .p-dataview-header {
    background: white !important;
    border: none !important;
    box-shadow: none !important;
}