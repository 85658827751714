.datatable-rowgroup-demo .p-rowgroup-footer td {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
    vertical-align: middle;
    margin-right: .25rem;
}

.p-datatable-wrapper{
    max-height: calc(100vh - 40vh) !important;
}

@media screen and (max-width: 576px) {
    .p-datatable-wrapper{
        max-height: calc(100vh - 44vh) !important;
    }
}

@media screen and (max-width: 390px) {
    .p-datatable-wrapper{
        max-height: calc(100vh - 46vh) !important;
    }
}


@media screen and (max-width: 376px) {
    .p-datatable-wrapper{
        max-height: calc(100vh - 52vh) !important;
    }
}

