.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    /*background: #4338CA; */
    color: #ffffff;
    /* border-color: #4338CA; */
    border-color: none !important;
}

.p-button {
    border: none !important;

}

.h-32rem {
  height: 32rem !important;
}

.h-40rem {
    height: 40rem !important;
}

.h-45rem {
    height: 45rem !important;
}

@media screen and (max-width: 576px) {
    .stiloTrue{
      
      object-fit: fill !important;
      
    }
    .hdef{
      height: 35rem !important;
    }

    .box .movede{
      transition: all 700ms !important;
    }
    .box #imagenPrueba img{
      transition: all 700ms;
    }
    .box #imagenPrueba img:hover{
      transform: scale(1.05);
    }

    .box-izq .moveiz{
      transition: all 700ms !important;
    }
    .box-izq #imagenPrueba img{
      transition: all 700ms;
    }
    .box-izq #imagenPrueba img:hover {
      transform: scale(1.05);
    }

    .fondoCate{
      bottom: -5% !important;
    }

    .fondoCate-izq{
      bottom: -5% !important;
    }
  }
  
  @media screen and (min-width: 576px) {
    .hdef{
      height: 45rem !important;
    }

    .box .movede{
      transition: all 700ms !important;
    }
    .box:hover .movede{
      margin-right: 2rem !important;
    }
    .box #imagenPrueba img{
      transition: all 700ms;
    }
    .box:hover #imagenPrueba img{
      transform: scale(1.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
    }

 
    .box-izq .moveiz{
      transition: all 700ms !important;
    }
    .box-izq:hover .moveiz{
      margin-left: 3srem  !important;
    }
    .box-izq #imagenPrueba img{
      transition: all 700ms;
    }
    .box-izq:hover #imagenPrueba img {
      transform: scale(1.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
    }
 
  }

  /*.fondoCate{
    background-color: #faf4e4 !important;
    bottom: -8%;
    left: 0px;
    right: auto;
    transform-origin: left center !important;
    will-change: transform !important;
    transition-timing-function: linear;
  }

  .box:hover .fondoCate{
    transform: translateY(300px) !important;
    transition: all 700ms ease-out;
    

    #faf4e4
  }*/

  .fondoCate{
    background: linear-gradient(to left, white, white,#cec8b7,#cec8b7);
    background-position: right bottom;
    /*background-position: center;*/
    bottom: -8%;
    background-size: 400% 100%;
    transition: all 1s ease-out;
    transition-timing-function: linear;
  }

  .box:hover .fondoCate{
    background-position: left bottom;
  }

  .fondoCate-izq{
    /*#faf4e4   #b9b199*/ 
    background: linear-gradient(to right, white, white,#c3bda8,#c3bda8);
    background-position: left bottom;
    /*background-position: center;*/
    bottom: -8% /*-8%*/;
    background-size: 400% 100%;
    transition: all 1s ease-out;
    transition-timing-function: linear;
  }

  .box-izq:hover .fondoCate-izq{
    background-position: right bottom;
  }

  .sombrita {
    text-shadow: 1px 1px 1px #e6e3da !important;
  }

  
  .cursor{
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: black;
    
    border-radius: 50%;
    pointer-events: none;
    z-index: 3;
  }
  
  .box:hover ~ .cursor{
    visibility: visible ;
    
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px black !important;
    border-color: black !important;
  }


  .leaflet-container {
    width: 100%;
    height: 100%;
  }

  .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--swiper-theme-color);
  }
  
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--swiper-theme-color);
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }