/* PickListDemo.css */

.picklist-demo .product-item {
    display: flex;
    align-items: center;
    padding: .5rem;
    width: 100%;
}

.picklist-demo .product-item img {
    width: 75px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
}

.picklist-demo .product-item .product-list-detail {
    flex: 1 1 0;
}

.picklist-demo .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.picklist-demo .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.picklist-demo .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}
.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badge.status-outofstock {
    background: #ffcdd2 !important;
    color: #c63737 !important;
}

.product-badge.status-instock {
    background: #c8e6c9 !important;
    color: #256029 !important;
}

.marca{
    color: #0f346c !important;
}
.rtf--mb{
    background-color: #22C55E !important;
}


@media screen and (max-width: 576px) {
    .picklist-demo .product-item {
        flex-wrap: wrap;
    }

    .picklist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .picklist-demo .product-item img {
        margin: 0 0 1rem 0;
        width: 100px;
    }
}




/* SpeedDialDemo.css */

