@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap');


* {
    backface-visibility: hidden;
    overscroll-behavior: none;
    -webkit-tap-highlight-color: transparent;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  user-select: none;
}

html {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  
}


body {
  margin: 0 !important;
  height:100% ;
  width: 100% ;
  padding: 0 !important;  
  overflow-x: hidden !important;
  overflow-y: scroll !important;  
  
  scroll-behavior: auto;
  font-family: 'Advent Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-button-next{
  color: #454546;
}
.swiper-button-prev{
  color: #454546;
}
.swiper-pagination-bullet-active{
  background-color: #454546;
}


/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/


  /**::-webkit-scrollbar {
      display: none;
  }*/
  
  *::-webkit-scrollbar {
    width: 0px;     /* Tamaño del scroll en vertical */
    height: 0px;    /* Tamaño del scroll en horizontal */
  }
  *::-webkit-scrollbar-thumb {
    background: #212121;
    border-radius: 0px;
  }



   *::-webkit-scrollbar-track {
    display: none;
}