.p-tree{
    padding: 0 !important;
    border: none !important;
}

#sidenav .p-sidebar-header{
    justify-content: space-between !important;
}

.sombrita {
    /*text-shadow: 1px 1px 3px rgba(0,0,0,0.99) !important; */
    color: rgba(255, 255, 255, 1) !important;
    text-shadow: 0px 0px 5px rgba(128, 0, 0, 1) !important;
    
      /*word-spacing: 10em !important;*/
      letter-spacing: 0.05em !important;
  }