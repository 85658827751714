

.custombar1 .p-scrollpanel-wrapper {
    border-right: 10px solid #f4f4f4;
}
.p-scrollpanel .p-scrollpanel-bar {
    background: #212121;
    border: 0 none;
}
.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar {
    background-color: #212121;
    opacity: 1;
    transition: background-color .3s;
}

.custombar1 .p-scrollpanel-bar:hover {
    background-color: red;
}

.p-selectbutton .p-button.p-highlight {
    background: #212121 !important;
    border-color: #212121 !important;
    color: #ffffff !important;
}

.p-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #878787, 0 1px 2px 0 rgb(0 0 0 / 0%) !important;
}

.botoncito:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    background: none !important;
    color: #212121 !important;
    border-color: #212121 !important;
}

.p-info {
    color: #f0a9a7 !important;
  }

.scrollsito {
    overflow-y: hidden !important;
}